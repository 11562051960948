import api from "./api";

export const fetchCities = async () => {
    try {
        const url = '/cities/'
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        return error;
    }
};