import React from "react";
import CounterInput from "../../../../components/zoekopdracht-components/CounterInput/CounterInput";

function StepAantalBadkamers({ formData, setFormData, nextStep }) {
    const handleChange = (newCount, name) => {
        setFormData({
            ...formData,
            [name]: newCount,
        });
    };

    return (
        <div id='aantalbadkamers' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Geef je voorkeur voor het aantal badkamers in jouw koopwoning</h1>
                    <p className="input-form-text-p">Vul hieronder de voorkeur in voor het aantal badkamers van de koopwoning.</p>
                </div>
                <CounterInput name='bathrooms' value={formData.bathrooms} onCountChange={handleChange} singular='Badkamer' plural='Badkamers' />
            </div>
        </div>
    );
}

export default StepAantalBadkamers;