import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Huis-Kopen.css";
import heroimage from "../../assets/img/expat-img-01.jpeg";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import usePageTracking from '../../utils/usePageTracking';

const HuisKopen: React.FC = () => {
    usePageTracking();
    const list = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.5,
                delayChildren: 0.5
            },
        },
    };

    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 },
    };

    const container = {
        show: {
            transition: {
                staggerChildren: 0.5
            }
        }
    }

    const iconDiv = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { ease: "backInOut", when: "beforeChildren", } },
    };

    const textchild = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { ease: "backInOut", when: "beforeChildren", } },
    };

    const textchildDelayed = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { delay: 0.5, ease: "backInOut", when: "beforeChildren", } },
    };

    const variants = {
        hidden: { y: 100, height: 0 },
        show: { y: 0, height: "100%", transition: { when: "beforeChildren", staggeringChildren: 0.5 } },
    };
    return (
        <div>
            <PageLayout>
                <Helmet>
                    <title>iBuy 020 | Huis kopen</title>
                    <meta name="description" content="Laat je stap voor stap geholpen worden bij het vinden van jouw nieuwe huis" />
                </Helmet>
                <motion.div className="main-hero-div" initial={{ scale: 0.5, y: 100, opacity: 0 }} whileInView={{ scale: 1, y: 0, opacity: 1 }} viewport={{ once: true }}>
                    <img src={heroimage} alt="Amsterdam gracht met kerk" className="main-hero-img" />
                    <div className="main-hero-content-div">
                        <div className="main-hero-inner-content-div">
                            <motion.h1 className="main-hero-title-h1" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 0.5 }} viewport={{ once: true }}>Huis kopen in Amsterdam?</motion.h1>
                            <motion.p className="main-hero-txt-p" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 1 }} viewport={{ once: true }}>Ben je op zoek naar een nieuw huis, maar weet je niet waar te beginnen? Geen zorgen, wij staan voor je klaar. Neem vandaag nog contact met ons op voor een vrijbmotion.lijvend advies en laat ons je begeleiden naar jouw droomhuis.</motion.p>
                        </div>
                    </div>

                </motion.div>
                <div className="huiskopen-content-area">
                    <div className="huiskopen-roadmap-div">
                        <motion.ul className="huiskopen-roadmap-listing" variants={container}>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">search</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Zoekopdracht</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Vul de zoekopdracht in via de website.</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content right-item">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">calendar_today</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Afspraak inplannen</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Wij nemen contact met
                                        u op zodat we een afspraak kunnen inplannen bij ons op kantoor.</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">home</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>In kaart brengen van uw ideale woning</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Tijdens het gesprek willen wij graag al uw wensen en eisen in kaart brengen.</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content right-item">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">search</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Start zoektocht</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Als dit realistisch en financieel haalbaar is met de huidige markt beginnen we met de zoektocht en wordt er een koopovereenkomst opgesteld en getekend. Tijdens de zoektocht naar jouw huis denk je misschien niet gemotion.lijk aan verduurzamen. Duurzaam wonen wordt steeds belangrijker met het oog op de toekomst. Wist je dat je korting kunt krijgen op de hypotheekrente als je je woning duurzaam verbouwt?</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">sell</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Bieden en onderhandelen</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Als we een geschikte woning voor je hebben gevonden dat past bij je wensen en financiële mogemotion.lijkheden, beginnen met bieden en welmotion.licht een onderhandel fase.</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content right-item">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">check</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Zijn we geaccepteerd?</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Gefeliciteerd! Nu is het tijd om een (voorlopig) koopcontract te tekenen. Hierin zijn alle afspraken rondom de koop vastgelegd.</motion.p>
                                </div>

                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">account_balance</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Hypotheek advies en afsluiten, dit is een belangrijk proces</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Kies je hypotheek zorgvuldig. Het is van belang dat uw hypotheek bij u past, voor nu en in de toekomst.</motion.p>
                                </div>
                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content right-item">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">account_balance</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>Naar de Notaris, uw nieuwe woning is inzicht</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Jouw notaris stelt de hypotheekakte en transportakte op.</motion.p>
                                </div>

                            </motion.li>
                            <motion.li className="huiskopen-roadmap-listing-item" variants={container}>
                                <div className="huiskopen-roadmap-inner-content">
                                    <div className="huiskopen-roadmap-flex-div">
                                        <motion.div className="huiskopen-roadmap-step-icon-div" variants={iconDiv} initial="hidden" whileInView="show" viewport={{ once: true }}>
                                            <span className="material-symbols-rounded huiskopen-roadmap-icon-span">key</span>
                                        </motion.div>
                                    </div>
                                    <motion.h3 className="huiskopen-roadmap-title-h3" variants={textchild} initial="hidden" whileInView="show" viewport={{ once: true }}>De Sleuteloverdracht</motion.h3>
                                    <motion.p className="huiskopen-roadmap-txt-p" variants={textchildDelayed} initial="hidden" whileInView="show" viewport={{ once: true }}>Voordat we naar de notaris gaan om de sleutel in ontvangst te nemen, gaan we samen na of het huis wordt opgeleverd zoals is afgesproken. Samen met de notaris gaan nog een laatste keer door de hypotheekakte en transportakte en krijg je de gelegenheid om vragen te stellen. Hierna zet u uw handtekening op het contract en bent u officieel de nieuwe eigenaar van het huis. <b>Gefeliciteerd</b>!</motion.p>
                                </div>

                            </motion.li>

                        </motion.ul>
                    </div>
                </div>
            </PageLayout>
        </div>
    );
}

export default HuisKopen;