import React from "react";
import CounterInput from "../../../../components/zoekopdracht-components/CounterInput/CounterInput.js";

function StepAantalSlaapkamers({ formData, setFormData, nextStep }) {
    const handleChange = (newCount, name) => {
        setFormData({
            ...formData,
            [name]: newCount,
        });
    };

    return (
        <div id='aantalslaapkamers' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Geef je voorkeur voor het aantal slaapkamers in jouw koopwoning</h1>
                    <p className="input-form-text-p">Vul hieronder de voorkeur in voor het aantal slaapkamers van de koopwoning.</p>
                </div>
                <CounterInput name='bedrooms' value={formData.bedrooms} onCountChange={handleChange} singular='Slaapkamer' plural='Slaapkamers' />
            </div>
        </div>
    );
}

export default StepAantalSlaapkamers;