import React from "react";
import { CheckboxGroup } from "@nextui-org/react";
import { CustomCheckbox } from "../../../../components/zoekopdracht-components/CustomCheckbox/CustomCheckbox.js";

function StepSelecteerLocations({ formData, setFormData, locations, validationErrors }) {
    const handleChange = (newValues) => {
        setFormData({
            ...formData,
            cities: newValues,
        });
    };

    return (
        <div id='locatie' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Kies de locatie(s)</h1>
                    <p className="input-form-text-p">Kies hieronder de locatie(s) van je nieuwe woning.</p>
                </div>
                <CheckboxGroup
                    name="cities"
                    className="gap-1"
                    orientation="horizontal"
                    value={formData.cities}
                    onChange={handleChange}
                >
                    {locations.map((location) => (
                        <CustomCheckbox key={location['city']} value={location['city']}>{location['city']}</CustomCheckbox>
                    ))}
                </CheckboxGroup>
                {validationErrors.cities && <div className="error-message">{validationErrors.cities}</div>}
            </div>
        </div>
    );
}

export default StepSelecteerLocations;