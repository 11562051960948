import axios from 'axios';

// Define headers using environment variables
const headers = {
    'access_token': process.env.REACT_APP_API_KEY
};

// Create axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers
});

// Add request interceptor
api.interceptors.request.use(config => {
    // Dynamically set the access token for each request
    config.headers['access_token'] = process.env.REACT_APP_API_KEY;
    return config;
}, error => {
    // Handle request errors here
    console.error('Request error:', error);
    return Promise.reject(error);
});

export default api;
