import * as React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Contact.css";
import { motion, stagger } from "framer-motion";
import { Helmet } from "react-helmet-async";
import usePageTracking from "../../utils/usePageTracking";

const Contact: React.FC = () => {
    usePageTracking();
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5
            }
        }
    };

    const textchild = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { ease: "backInOut", when: "beforeChildren", } },
    };

    return (
        <div>
            <PageLayout>
                <Helmet>
                    <title>iBuy 020 | Contact</title>
                    <meta name="description" content="Heeft u vragen of wilt u graag kennis met ons maken? Neem contact op met ons voor een vrijblijvend advies. U kunt natuurlijk ook altijd even bij ons langs komen voor een bezoek aan kantoor." />
                </Helmet>
                <motion.div className="map" variants={textchild}>
                    <iframe className="iframe-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.165804102239!2d4.352874315783643!3d52.01157697973199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5f0a7f6f1b6c1%3A0x7b1f9b6a1f5b6c7!2sNext%20Tech%20HQ!5e0!3m2!1snl!2snl!4v1634774285270!5m2!1snl!2snl" width="100%" height="450" style={{ border: 0 }} loading="lazy"></iframe>
                </motion.div>
                <motion.div className="contact-content" variants={container} initial="hidden" whileInView="show">
                    <motion.h1 className="website-title-h1" variants={textchild}>
                        Op zoek naar je nieuwe droomhuis? Wilt u uw woning zo goed mogelijk verkopen?
                    </motion.h1>
                    <motion.p className="website-text-p" variants={textchild}>
                        Heeft u vragen? Of zou je graag kennis met ons willen maken? Neem contact op met ons voor een vrijblijvend advies. Je kan natuurlijk ook altijd even bij ons langs komen voor een bezoek aan kantoor.
                    </motion.p>
                    <div className="contact-info-bar">
                        <div id="contact-page-adress-info-inner-area-div">
                            <motion.p className="contact-oage-address-info-txt-p" variants={textchild}>iBuy 020<br />Eemstraat 68 H<br />1079 TK Amsterdam</motion.p>
                            <motion.p className="contact-page-bedrijfs-info-value-p" variants={textchild}><span className="contact-page-bedrijfs-var">E</span> info@ibuy020.nl</motion.p>
                            <motion.p className="contact-page-bedrijfs-info-value-p" variants={textchild}><span className="contact-page-bedrijfs-var">T</span> 020 24 40 291</motion.p>
                            <motion.p className="contact-page-bedrijfs-info-value-p" variants={textchild}><span className="contact-page-bedrijfs-var">KVK</span> 74273353</motion.p>
                            <motion.p className="contact-page-bedrijfs-info-value-p" variants={textchild}><span className="contact-page-bedrijfs-var">IBAN</span> NL42INGB0006201929</motion.p>
                        </div>
                        <motion.div id="contact-page-schedule-info-inner-area-div" variants={textchild}>
                            <p className="contact-page-schedule-title-txt-p">Openingstijden</p>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Maandag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">09:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Dinsdag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">09:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Woensdag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">09:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Donderdag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">09:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Vrijdag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">09:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Zaterdag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">Op afspraak</p>
                                </div>
                            </div>
                            <div className="contact-page-schedule-row-info-area-div">
                                <div className="contact-page-schedule-days-area-div">
                                    <p className="contact-page-schedule-days-txt-p">Zondag</p>
                                </div>
                                <div className="contact-page-schedule-times-area-div">
                                    <p className="contact-page-schedule-times-txt-p">Op afspraak</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <motion.div className="contact-page-options-area-div" variants={textchild}>
                        <ul className="contact-page-options-listing-ul">
                            <li className="contact-page-option-item-li">
                                <div className="contact-page-option-item-inner-div">
                                    <div className="contact-page-option-item-icon-div">
                                        <span className="contact-page-option-icon-span material-symbols-rounded">phone</span>
                                    </div>
                                    <div className="contact-page-option-item-txt-div">
                                        <p className="contact-page-option-item-txt-p">Neem contact op via telefoon</p>
                                    </div>
                                </div>
                            </li>
                            <li className="contact-page-option-item-li">
                                <div className="contact-page-option-item-inner-div">
                                    <div className="contact-page-option-item-icon-div">
                                        <span className="contact-page-option-icon-span material-symbols-rounded">mail</span>
                                    </div>
                                    <div className="contact-page-option-item-txt-div">
                                        <p className="contact-page-option-item-txt-p">Neem contact op via e-mail</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </motion.div>
                </motion.div>
            </PageLayout>
        </div>
    );
}

export default Contact;