import React from 'react';
import {CircularProgress} from "@nextui-org/react";
import './LoadingPopUp.css';

const LoadingPopUp = () => {
    return (
        <div className="loading-popup">
            <CircularProgress classNames={{
                svg: "w-40 h-40",
            }}
             size="lg" aria-label="Loading..."/>
        </div>
    );
};

export default LoadingPopUp;