import React from 'react';
import './NavBar.css';
import logo from '../../../assets/img/ibuy-logo.jpg';
import { Link } from 'react-router-dom';
import RowSteps from '../SimpleStepper/row-steps';


const NavBar = () => {
    return (
        <nav id="main-zoekopdracht-nav">
            <ul id="main-zoekopdracht-listing">
                <li id="main-zoekopdracht-logo-li">
                    <Link to="/">
                        <img src={logo} alt="ibuy020 logo" id="main-zoekopdracht-logo-img" />
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;