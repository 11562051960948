import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../components/zoekopdracht-components/NavBar/NavBar.js";
import './Zoekopdracht.css';
import StepBudget from "./Steps/StepBudget/StepBudget.js";
import StepTypeWoning from "./Steps/StepTypeWoning/StepTypeWoning.js";
import StepAantalSlaapkamers from "./Steps/StepAantalSlaapkamers/StepAantalSlaapkamers.js";
import StepAantalBadkamers from "./Steps/StepAantalBadkamers/StepAantalBadkamers.js";
import StepSelecteerOpties from "./Steps/StepSelecteerOpties/StepSelecteerOpties.js";
import StepAantalVierkanteMeters from "./Steps/StepAantalVierkanteMeters/StepAantalVierkanteMeters.js";
import StepSelecteerLocations from "./Steps/StepSelecteerLocations/StepSelecteerLocations.js";
import StepGegevens from "./Steps/StepGegevens/StepGegevens.js";
import StepOndertekenen from "./Steps/StepOndertekenen/StepOndertekenen.js";
import { fetchOptions } from "../../api/optionsService.ts";
import { fetchCities } from "../../api/citiesService.ts";
import { addSearchAssignment } from "../../api/searchAssignmentService/searchAssignmentService.ts";
import CompletedPopUp from "../../components/zoekopdracht-components/CompletedPopUp/CompletedPopUp.jsx";
import LoadingPopUp from "../../components/zoekopdracht-components/LoadingPopUp/LoadingPopUp.jsx";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import usePageTracking from '../../utils/usePageTracking';

function generateNumber() {
    const section = () => Math.floor(1000 + Math.random() * 9000);
    return `${section()}-${section()}-${section()}`;
}

const Zoekopdracht = () => {
    usePageTracking();
    const [formData, setFormData] = useState({
        budget: '',
        property_type: '',
        bedrooms: 1,
        bathrooms: 1,
        options: [],
        square_meters: 40,
        cities: [],
        user_name: '',
        user_lname: '',
        user_email: '',
        user_phone: '',
        user_street: '',
        user_housenumber: '',
        user_postalcode: '',
        user_city: '',
        user_country: '',
        city_signature: '',
        signature: '',
    });
    const [currentStep, setCurrentStep] = useState(1);

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = (formData) => {
        const errors = {};
        switch (currentStep) {
            case 1:
                if (!formData.budget) errors.budget = 'Je moet wel je budget invullen!';
                if (formData.budget && parseValue(formData.budget) < 200000) errors.budget = 'Je budget moet minimaal 200.000 euro zijn!';
                break;
            case 2:
                if (!formData.property_type) errors.property_type = 'Je moet wel het type woning invullen!';
                break;
            case 3:
                break;
            case 4:
                break;
            case 5:
                break;
            case 6:
                break;
            case 7:
                if (formData.cities.length === 0) errors.cities = 'Je moet wel minimaal één locatie selecteren!';
                break;
            case 8:
                if (!formData.user_name) errors.user_name = 'Je moet wel je voornaam invullen!';
                if (!formData.user_lname) errors.user_lname = 'Je moet wel je achternaam invullen!';
                if (!formData.user_email) {
                    errors.user_email = 'Je moet wel je email invullen!';
                } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
                    errors.user_email = 'Je email is niet geldig!';
                }
                if (!formData.user_phone) errors.user_phone = 'Je moet wel je telefoonnummer invullen!';
                if (!formData.user_street) errors.user_street = 'Je moet wel je straat invullen!';
                if (!formData.user_housenumber) errors.user_housenumber = 'Je moet wel je huisnummer invullen!';
                if (!formData.user_postalcode) errors.user_postalcode = 'Je moet wel je postcode invullen!';
                if (!formData.user_city) errors.user_city = 'Je moet wel je stad invullen!';
                if (!formData.user_country) errors.user_country = 'Je moet wel je land invullen!';
                break;
            case 9:
                if (!formData.city_signature) errors.city_signature = 'Je moet wel je stad invullen van ondertekening!';
                if (!formData.signature || formData.signature === '') {
                    errors.signature = 'Je moet wel je handtekening zetten!';
                }
                break;
            // Add similar cases for other steps
            default:
                break;
        }
        setValidationErrors(errors);
        return errors;
    };

    function parseValue(value) {
        const withoutPeriods = value.replace(/\./g, '');
        const withDotDecimal = withoutPeriods.replace(',', '.');
        return parseFloat(withDotDecimal);
    }

    const nextStep = () => {
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const signaturePadRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const signaturePad = signaturePadRef.current;
        let newFormData = { ...formData };

        if (!signaturePad.isEmpty()) {
            console.log('Signature is not empty');
            const signature = signaturePad.toDataURL();
            newFormData = {
                ...newFormData,
                signature: signature,
            };
        }

        const errors = validateForm(newFormData);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            setFormData(newFormData);
            console.log(newFormData);
            const searchID = generateNumber();
            const correctFormatBudget = parseValue(newFormData.budget);
            let proteryType = parseInt(newFormData.property_type);
            newFormData = {
                ...newFormData,
                search_id: searchID,
                budget: correctFormatBudget,
                property_type: proteryType,
            };
            try {
                const response = await addSearchAssignment(newFormData);
                console.log(response);
                setCompleted(true);
                setIsLoading(false);
                setTimeout(() => {
                    navigate('/zoekopdracht-voltooid?z=' + searchID);
                }, 10000);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            } finally {
                console.log('Form submitted');
            }
        }
    };

    const woningTypes = [{ id: 1, label: 'Appartement', value: 'appartement' }, { id: 2, label: 'Twee onder een kap', value: 'twee-onder-een-kap' }, { id: 3, label: 'Vrijstaand', value: 'vrijstaand' }];

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptionsData = async () => {
            try {
                const response = await fetchOptions();
                console.log(response);
                setOptions(response);
            } catch (error) {
                console.error(error);
            } finally {
                console.log('Options fetched');
            }
        }

        fetchOptionsData();
    }, []);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const fetchLocationsData = async () => {
            try {
                const response = await fetchCities();
                setLocations(response);
            } catch (error) {
                console.error(error);
            } finally {
                console.log('Locations fetched');
            }
        }

        fetchLocationsData();
    }, []);

    return (
        <div className="zoekopdracht-main-view">
            <Helmet>
                <title>iBuy 020 | Zoekopdracht naar je droomhuis</title>
                <meta name="description" content="Vind jouw droomhuis met iBuy020. Wij begeleiden je persoonlijk door het aankoopproces en zorgen voor de beste deal. Altijd bereikbaar, zelfs buiten kantooruren. Kies iBuy020 voor een zorgeloze woningkoop." />
            </Helmet>
            <NavBar />
            <div className="zoekopdracht-view">
                {isLoading ? <LoadingPopUp /> : null}
                {completed ? <CompletedPopUp /> : null}
                <div id="inner-form-content">

                    {currentStep === 1 && <StepBudget formData={formData} setFormData={setFormData} validationErrors={validationErrors} />}

                    {currentStep === 2 && <StepTypeWoning formData={formData} setFormData={setFormData} validationErrors={validationErrors} woningTypes={woningTypes} />}

                    {currentStep === 3 && <StepAantalSlaapkamers formData={formData} setFormData={setFormData} />}

                    {currentStep === 4 && <StepAantalBadkamers formData={formData} setFormData={setFormData} />}

                    {currentStep === 5 && <StepSelecteerOpties formData={formData} setFormData={setFormData} options={options} />}

                    {currentStep === 6 && <StepAantalVierkanteMeters formData={formData} setFormData={setFormData} />}

                    {currentStep === 7 && <StepSelecteerLocations formData={formData} setFormData={setFormData} locations={locations} validationErrors={validationErrors} />}

                    {currentStep === 8 && <StepGegevens formData={formData} setFormData={setFormData} validationErrors={validationErrors} signaturePadRef={signaturePadRef} />}

                    {currentStep === 9 && <StepOndertekenen formData={formData} setFormData={setFormData} validationErrors={validationErrors} signaturePadRef={signaturePadRef} />}

                    <div className="form-control-buttons">
                        {currentStep > 1 && <button className='half-submit-buttons' onClick={prevStep}><span className="material-symbols-rounded submit-icon" translate='no'>arrow_back</span></button>}
                        {currentStep < 9 && <button className='half-submit-buttons' onClick={nextStep}><span className="material-symbols-rounded submit-icon" translate='no'>arrow_forward</span></button>}
                        {currentStep === 9 && <button className='half-submit-buttons' onClick={handleSubmit}>Voltooien</button>}
                    </div>

                </div>



            </div>
        </div>

    );
}

export default Zoekopdracht;