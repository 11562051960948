import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageLayout from "../../components/PageLayout/PageLayout";
import "./ZoekopdrachtVoltooid.css";
import { getSearchAssignmentByColumn } from "../../api/searchAssignmentService/searchAssignmentService";
import { SearchAssignment } from "../../models/SearchAssignment";
import { Helmet } from "react-helmet-async";
import usePageTracking from '../../utils/usePageTracking';

const ZoekopdrachtVoltooid: React.FC = () => {
    usePageTracking();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const searchid = queryParams.get('z');
    console.log('Query params:', searchid);

    const [searchAssignment, setSearchAssignment] = React.useState<SearchAssignment>();

    useEffect(() => {
        const loadSearchAssignment = async () => {
            try {
                const response = await getSearchAssignmentByColumn("search_id", searchid);
                setSearchAssignment(response);
            } catch (error) {
                console.error(error);
            }
        }
        loadSearchAssignment();

    }, []);
    return (
        <div>
            <PageLayout>
                <Helmet>
                    <title>iBuy 020 | Zoekopdracht voltooid</title>
                </Helmet>
                <div className="main-zoekopdrachtvoltooid-hero">
                    <div className="main-zoekopdrachtvoltooid-hero-inner-div">
                        <h1 className="main-zoekopdrachtvoltooid-title-h1">
                            <span className="main-zoekopdrachtvoltooid-top-txt-span">Gelukt {searchAssignment?.user_name},</span><br></br>
                            Bedankt voor het kiezen van iBuy 020. Je zoekopdracht is verzonden! Je kunt nu je account aanmaken.
                        </h1>
                    </div>
                </div>
                <div className="main-zoekopdrachtvoltooid-content-area">
                    <div className="main-zoekopdrachtvoltooid-verloop-area">
                        <h3 className="main-zoekopdrachtvoltooid-verloop-title-h3">
                            Hoe gaat het nu verder?
                        </h3>
                        <div className="zoekopdracht-verloop-area-div">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">assignment</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">Opdrachtbevestiging</h4>
                                <p className="zoekopdracht-verloop-txt-p">Je zoekopdracht is verzonden en wordt nu verwerkt. We hebben een e-mail verstuurd naar {searchAssignment?.user_email}</p>
                            </div>
                        </div>

                        <div className="zoekopdracht-verloop-area-div">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">home</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">Woning zoeken</h4>
                                <p className="zoekopdracht-verloop-txt-p">Wanneer wij genoeg informatie van jou hebben en wij effectief te werk kunnen gaan met het zoeken naar de juiste huurwoning, zullen wij contact met jou opnemen.</p>
                            </div>
                        </div>

                        <div className="zoekopdracht-verloop-area-div">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">search</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">Start zoektocht</h4>
                                <p className="zoekopdracht-verloop-txt-p">Als dit realistisch en financieel haalbaar is met de huidige markt beginnen we met de zoektocht en wordt er een koopovereenkomst opgesteld en getekend. Tijdens de zoektocht naar jouw huis denk je misschien niet gemotion.lijk aan verduurzamen. Duurzaam wonen wordt steeds belangrijker met het oog op de toekomst. Wist je dat je korting kunt krijgen op de hypotheekrente als je je woning duurzaam verbouwt?</p>
                            </div>
                        </div>

                        <div className="zoekopdracht-verloop-area-div">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">sell</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">Bieden en onderhandelen</h4>
                                <p className="zoekopdracht-verloop-txt-p">Als we een geschikte woning voor je hebben gevonden dat past bij je wensen en financiële mogemotion.lijkheden, beginnen met bieden en welmotion.licht een onderhandel fase.</p>
                            </div>
                        </div>

                        <div className="zoekopdracht-verloop-area-div">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">account_balance</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">Naar de Notaris, uw nieuwe woning is inzicht</h4>
                                <p className="zoekopdracht-verloop-txt-p">Jouw notaris stelt de hypotheekakte en transportakte op.</p>
                            </div>
                        </div>

                        <div className="zoekopdracht-verloop-area-div last">
                            <div className="zoekopdracht-verloop-icon-div">
                                <div className="zoekopdracht-verloop-inner-icon-div">
                                    <span className="zoekopdracht-verloop-icon-span material-symbols-outlined">key</span>
                                </div>
                            </div>
                            <div className="zoekopdracht-verloop-txt-area-div">
                                <h4 className="zoekopdracht-verloop-txt-title-h4">De Sleuteloverdracht</h4>
                                <p className="zoekopdracht-verloop-txt-p">Voordat we naar de notaris gaan om de sleutel in ontvangst te nemen, gaan we samen na of het huis wordt opgeleverd zoals is afgesproken. Samen met de notaris gaan nog een laatste keer door de hypotheekakte en transportakte en krijg je de gelegenheid om vragen te stellen. Hierna zet u uw handtekening op het contract en bent u officieel de nieuwe eigenaar van het huis. <b>Gefeliciteerd</b>!</p>
                            </div>
                        </div>


                    </div>

                </div>
            </PageLayout>
        </div>
    );
}

export default ZoekopdrachtVoltooid;