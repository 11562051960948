import React from 'react';
import './PageLayout.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

interface PageLayoutProps {
    children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    return (
        <div id='main-pagelayout'>
            <NavBar />
            <div id='main-content'>
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default PageLayout;