import React from 'react';
import './Footer.css';
import logo from '../../assets/img/ibuy-logo.jpg';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
    return (
        <footer id='main-footermenu'>
            <div id='footer-content'>
                <ul id="main-web-footer-listing">
                    <li id="main-footer-logo-li">
                        <img src={logo} alt='ibuy020 logo' id="main-web-footer-logo-img" />
                    </li>
                    <li id="main-web-footer-li">
                        <ul id="main-web-footer-inner-listing">
                            <li id="main-web-footer-inner-li">
                                <p id="main-web-footer-adres-txt-p">iBuy 020 <br /> Eemstraat 68 H <br /> 1079 TK Amsterdam</p>
                                <p id="main-web-footer-info-txt-p">
                                    <span id="main-web-footer-info-var-span">E</span>info@ibuy020.nl<br />
                                    <span id="main-web-footer-info-var-span">T</span>+31 6 16 16 62 77<br />
                                    <span id="main-web-footer-info-var-span">KVK</span>74273353<br />
                                    <span id="main-web-footer-info-var-span">IBAN</span>NL 42 INGB 0006 2019 29<br />
                                    <span id="main-web-footer-info-var-span">BIC</span>INGBNL2A
                                </p>
                                <p id="main-web-foter-times-txt-p">
                                    <b>Bereikbaarheid</b><br />
                                    Ma-Vrij: <b>9:00-18:00</b><br />
                                    Buiten kantoortijden: <b>Op afspraak</b>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li id="main-web-footer-li">
                        <p id="main-web-footer-links-title-txt-p">Site Links</p>
                        <p id="main-web-footer-link-txt-p"><Link to='/' className='main-webfooter-link-href'>Home</Link></p>
                        <p id="main-web-footer-link-txt-p"><Link to='/huis-kopen' className='main-webfooter-link-href'>Huis Kopen</Link></p>
                        <p id="main-web-footer-link-txt-p"><Link to='/contact' className='main-webfooter-link-href'>Contact</Link></p>
                    </li>

                    <li id="main-web-footer-li">
                        <p id="main-web-footer-links-title-txt-p">Handige Links</p>
                        <p id="main-web-footer-link-txt-p"><Link to='/algemene-voorwaarden' className='main-webfooter-link-href'>Algemene voorwaarden</Link></p>
                        <p id="main-web-footer-link-txt-p"><Link to='/privacy-beleid' className='main-webfooter-link-href'>Privacy beleid</Link></p>
                    </li>

                    <li id="main-web-footer-li">
                        <p id="main-web-footer-links-title-txt-p">Onze huurafdelingen</p>
                        <p id="main-web-footer-link-txt-p"><a href="https://irent020.nl" id="main-webfooter-link-href">Amsterdam</a></p>
                    </li>
                </ul>
            </div>
            <div id='main-web-footer-under-bar'>
                <ul id="main-web-footer-under-listing">
                    <li id="main-web-footer-under-logo-li">
                        <p id="main-web-footer-under-copyright-txt-p">© {currentYear} iBuy 020</p>
                    </li>
                    <li id="main-web-footer-under-links-li">
                        <ul id="main-web-footer-under-links-listing">
                            <li id="main-web-footer-under-link-li">
                                <p id="main-web-footer-under-link-txt-p"><Link to='/algemene-voorwaarden' className='main-webfooter-link-href'>Algemene Voorwaarden</Link></p>
                            </li>
                            <li id="main-web-footer-under-link-li">
                                <p id="main-web-footer-under-link-txt-p"><Link to='/privacy-beleid' className='main-webfooter-link-href'>Privacy Beleid</Link></p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;