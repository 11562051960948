import React from "react";
import { Input } from "@nextui-org/react";
import './StepGegevens.css';

function StepGegevens({ formData, setFormData, validationErrors, signaturePadRef }) {
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <div id='gegevens' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Vul je gegevens in</h1>
                    <p className="input-form-text-p">Vul hieronder je gegevens in zodat wij contact met je kunnen opnemen.</p>
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_name'
                        type="text"
                        label="Voornaam"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_name && <div className="error-message">{validationErrors.user_name}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_lname'
                        type="text"
                        label="Achternaam"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_lname && <div className="error-message">{validationErrors.user_lname}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_email'
                        type="email"
                        label="E-mailadres"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_email && <div className="error-message">{validationErrors.user_email}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_phone'
                        type="tel"
                        label="Telefoonnummer"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_phone && <div className="error-message">{validationErrors.user_phone}</div>}
                </div>
                <h2 className="input-form-title-h2">Adresgegevens</h2>
                <div className="input-form-bar">
                    <Input
                        name='user_street'
                        type="text"
                        label="Straat"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_street && <div className="error-message">{validationErrors.user_street}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_housenumber'
                        type="text"
                        label="Huisnummer"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_housenumber && <div className="error-message">{validationErrors.user_housenumber}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_postalcode'
                        type="text"
                        label="Postcode"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_postalcode && <div className="error-message">{validationErrors.user_postalcode}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_city'
                        type="text"
                        label="Plaats"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_city && <div className="error-message">{validationErrors.user_city}</div>}
                </div>
                <div className="input-form-bar">
                    <Input
                        name='user_country'
                        type="text"
                        label="Land"
                        variant="bordered"
                        onChange={handleChange}
                        size="lg"
                        className="input-form-gegevens"
                    />
                    {validationErrors.user_country && <div className="error-message">{validationErrors.user_country}</div>}
                </div>
            </div>
        </div>
    )
}

export default StepGegevens;