import React, { useState } from 'react';
import './CounterInput.css';

const CounterInput = ({ name, value, onCountChange, singular, plural }) => {
    const [count, setCount] = useState(value);

    const increment = () => {
        const newCount = count + 1;
        setCount(newCount);
        onCountChange(newCount, name);
    };

    const decrement = () => {
        if (count === 1) {
            return;
        }
        const newCount = count - 1;
        setCount(newCount);
        onCountChange(newCount, name);
    };

    const itemText = count === 1 ? singular : plural;

    return (
        <div className='counter-container'>
            <button className='counter-button' onClick={decrement}><span className="material-symbols-rounded" translate='no'>remove</span></button>
            <input name={name} className='counter-input' type="text" value={`${count} ${itemText}`} readOnly />
            <button className='counter-button' onClick={increment}><span className="material-symbols-rounded" translate='no'>add</span></button>
        </div>
    );
};

export default CounterInput;