import React from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import './GeneralTermsView.css';
import { Helmet } from "react-helmet-async";
import usePageTracking from '../../utils/usePageTracking';

const GeneralTermsView: React.FC = () => {
    usePageTracking();
    return (
        <PageLayout>
            <Helmet>
                <title>iBuy 020 | Algemene voorwaarden</title>
                <meta name="description" content="Lees hier de algemene voorwaarden van iBuy020." />
            </Helmet>
            <div className="general-terms-inner-content">
                <h1 className="general-terms-title">Artikel 1. Definities</h1>
                <p className="general-terms-text">1.1. De met een hoofdletter geschreven termen hebben in deze algemene voorwaarden de volgende betekenis, tenzij uitdrukkelijk anders is aangegeven of uit de context anders blijkt:</p>
                <ul>
                    <li className="general-terms-text"><strong>Onderneming</strong>: De rechtspersoon of natuurlijke persoon die handelt in de uitoefening van zijn beroep of bedrijf;</li>
                    <li className="general-terms-text"><strong>iBuy020</strong>: De gebruiker van deze algemene voorwaarden: iBuy020, gevestigd aan de Eemsstraat 68H te Amsterdam, Nederland, ingeschreven bij de Kamer van Koophandel onder CoC-nummer 74273353;</li>
                    <li className="general-terms-text"><strong>Aankoopovereenkomst</strong>: De koopovereenkomst of een vergelijkbare overeenkomst tussen de Klant en een verkoper;</li>
                    <li className="general-terms-text"><strong>Klant</strong>: De natuurlijke persoon of rechtspersoon die een overeenkomst met iBuy020 is aangegaan;</li>
                    <li className="general-terms-text"><strong>Overeenkomst</strong>: De overeenkomst tussen iBuy020 en de Klant op basis waarvan iBuy020 bemiddelt bij de aankoop van een woning door de Klant;</li>
                    <li className="general-terms-text"><strong>Natuurlijk Persoon</strong>: De natuurlijke persoon die niet handelt in de uitoefening van een beroep of bedrijf;</li>
                    <li className="general-terms-text"><strong>Schriftelijk</strong>: Op schrift of per e-mail.</li>
                </ul>
                <p className="general-terms-text">1.2. Tenzij uit de context anders blijkt, verwijzen de bovengenoemde definities in enkelvoud tevens naar het meervoud.</p>

                <h1 className="general-terms-title">Artikel 2. Algemeen</h1>
                <p className="general-terms-text">2.1 Deze algemene voorwaarden zijn van toepassing op alle Overeenkomsten die worden gesloten tussen iBuy020 en de Klant met betrekking tot de aankoop van een woning.</p>
                <p className="general-terms-text">2.2 Afwijkingen van deze algemene voorwaarden zijn slechts geldig indien schriftelijk overeengekomen.</p>
                <p className="general-terms-text">2.3 Deze algemene voorwaarden zijn tevens van toepassing op aanvullende en opvolgende opdrachten met betrekking tot aankoopbemiddeling. De Klant met wie eerder onder deze algemene voorwaarden een Overeenkomst is gesloten, wordt geacht de toepasselijkheid van deze algemene voorwaarden ook ten aanzien van latere met iBuy020 gesloten Overeenkomsten te hebben aanvaard.</p>
                <p className="general-terms-text">2.4 iBuy020 wijst de toepasselijkheid van de algemene (koop)voorwaarden van de als Klant handelende Onderneming uitdrukkelijk van de hand.</p>
                <p className="general-terms-text">2.5 Indien één of meer bepalingen van deze algemene voorwaarden nietig of vernietigbaar blijken te zijn, blijven de overige bepalingen van deze algemene voorwaarden volledig van kracht. iBuy020 zal in dat geval de nietige of vernietigde bepalingen vervangen met inachtneming van het doel en de strekking van de oorspronkelijke bepalingen.</p>
                <p className="general-terms-text">2.6 Voor zover iBuy020 niet steeds strikte naleving van deze algemene voorwaarden verlangt, betekent dit niet dat de bepalingen daarvan niet van toepassing zijn of dat iBuy020 het recht verliest om in andere gevallen strikte naleving van de bepalingen te verlangen.</p>
                <p className="general-terms-text">2.7 iBuy020 heeft het recht om deze algemene voorwaarden tijdens de looptijd van de Overeenkomst te wijzigen. De Klant wordt schriftelijk geïnformeerd over de nieuwe versie van de algemene voorwaarden en de ingangsdatum daarvan. Indien de gewijzigde algemene voorwaarden voor de Klant nadelige gevolgen hebben, kan de Klant de Overeenkomst beëindigen met ingang van de dag waarop de gewijzigde algemene voorwaarden in werking treden.</p>

                <h1 className="general-terms-title">Artikel 3. Aanbod</h1>
                <p className="general-terms-text">3.1 Het aanbod van iBuy020 is vrijblijvend.</p>
                <p className="general-terms-text">3.2 Kennelijke vergissingen of fouten in Overeenkomsten, e-mailberichten of op de website van iBuy020 binden iBuy020 niet.</p>
                <p className="general-terms-text">3.3 Vermelde prijzen en tarieven gelden niet automatisch voor toekomstige Overeenkomsten.</p>

                <h1 className="general-terms-title">Artikel 4. Totstandkoming van de Overeenkomst en Exclusiviteit</h1>
                <p className="general-terms-text">4.1 De Overeenkomst komt tot stand op het moment dat beide partijen de Overeenkomst ondertekenen (digitaal) of op het moment dat de Klant het aanbod van iBuy020 op andere wijze uitdrukkelijk heeft aanvaard.</p>
                <p className="general-terms-text">4.2 Deze algemene voorwaarden zijn voor of bij het sluiten van de Overeenkomst aan de Klant verstrekt.</p>
                <p className="general-terms-text">4.3 De Overeenkomst wordt aangegaan op basis van exclusiviteit. Zonder voorafgaande schriftelijke toestemming van iBuy020 mag de Klant gedurende de looptijd van de Overeenkomst geen derde inschakelen voor bemiddeling bij de aankoop van een woning.</p>

                <h1 className="general-terms-title">Artikel 5. Bedenktijd</h1>
                <p className="general-terms-text">5.1 Indien de Klant een Natuurlijk Persoon is, heeft deze het recht om de Overeenkomst die op afstand is gesloten of de Overeenkomst die niet is gesloten op de kantoren van iBuy020 te ontbinden gedurende een termijn van 14 dagen na de totstandkoming ervan. Een dergelijke ontbinding kan plaatsvinden door middel van een schriftelijke verklaring.</p>
                <p className="general-terms-text">5.2 Indien op uitdrukkelijk verzoek van de Klant is begonnen met de uitvoering van de Overeenkomst voordat de bedenktijd is verstreken en de Klant de Overeenkomst tijdens de bedenktijd ontbindt, brengt iBuy020 een bedrag in rekening dat evenredig is aan het deel van de Overeenkomst dat reeds is uitgevoerd.</p>

                <h1 className="general-terms-title">Artikel 6. Annulering</h1>
                <p className="general-terms-text">6.1 Indien de Klant een Onderneming is en de Overeenkomst annuleert of indien de Klant een Natuurlijk Persoon is die de Overeenkomst annuleert na het verstrijken van de bedenktijd, worden de tot op het moment van annulering verrichte werkzaamheden in rekening gebracht bij de Klant.</p>
                <p className="general-terms-text">6.2 Annulering dient schriftelijk plaats te vinden.</p>

                <h1 className="general-terms-title">Artikel 7. Looptijd van de Overeenkomst en Beëindiging van de Overeenkomst</h1>
                <p className="general-terms-text">7.1 De Overeenkomst wordt aangegaan voor bepaalde tijd van 1 jaar.</p>
                <p className="general-terms-text">7.2 De Overeenkomst eindigt:</p>
                <ul>
                    <li className="general-terms-text">a. Na afloop van de looptijd tenzij partijen overeenkomen de Overeenkomst te verlengen;</li>
                    <li className="general-terms-text">b. Door voltooiing van de Overeenkomst;</li>
                    <li className="general-terms-text">c. Door annulering van de opdracht door de Klant;</li>
                    <li className="general-terms-text">d. Door ontbinding door een der partijen.</li>
                </ul>
                <p className="general-terms-text">7.3 Bij aankoop is de Overeenkomst voltooid op het moment dat de Klant en de verkoper de Aankoopovereenkomst ondertekenen.</p>

                <h1 className="general-terms-title">Artikel 8. Wijzigingen van de Overeenkomst</h1>
                <p className="general-terms-text">8.1 Indien tijdens de uitvoering van de Overeenkomst blijkt dat het werk dat moet worden verricht moet worden aangepast of aangevuld om een goede uitvoering mogelijk te maken, zullen partijen in onderling overleg tijdig besluiten de Overeenkomst te wijzigen.</p>
                <p className="general-terms-text">8.2 iBuy020 zal de Klant informeren indien de wijziging of aanvulling van de Overeenkomst financiële en/of kwalitatieve consequenties heeft.</p>

                <h1 className="general-terms-title">Artikel 9. Uitvoering van de Overeenkomst</h1>
                <p className="general-terms-text">9.1 Alle verplichtingen van iBuy020 voortvloeiend uit de Overeenkomst zijn inspanningsverplichtingen.</p>
                <p className="general-terms-text">9.2 iBuy020 zal haar werkzaamheden naar beste kunnen uitvoeren als zorgvuldig vakbekwaam professional. Echter, iBuy020 kan niet garanderen dat een beoogd resultaat wordt bereikt. iBuy020 garandeert niet dat er een geschikte woning voor de Klant wordt gevonden of dat een woning waarin de Klant geïnteresseerd is, kan worden bezichtigd.</p>
                <p className="general-terms-text">9.3 iBuy020 heeft het recht om derden in te schakelen, goederen van derden te kopen, diensten van derden af te nemen en de Overeenkomst geheel of gedeeltelijk door derden te laten uitvoeren, zonder voorafgaande kennisgeving aan de Klant.</p>
                <p className="general-terms-text">9.4 iBuy020 zal nooit verplicht zijn werkzaamheden te verrichten die in strijd zijn met haar professionaliteit, een recht van een derde, een wettelijke verplichting of de gangbare normen van fatsoen.</p>
                <p className="general-terms-text">9.5 iBuy020 zal de Klant op verzoek informeren over de voortgang van de werkzaamheden.</p>

                <h1 className="general-terms-title">Artikel 10. Uitvoeringstermijn</h1>
                <p className="general-terms-text">10.1 Indien iBuy020 en de Klant een uitvoeringstermijn voor de diensten hebben afgesproken, wordt deze termijn geacht indicatief te zijn. iBuy020 garandeert de afgesproken uitvoeringstermijnen niet, en eventuele vertragingen geven de Klant geen recht op schadevergoeding.</p>
                <p className="general-terms-text">10.2 Indien een opgegeven leveringstermijn wordt overschreden door omstandigheden buiten de macht van iBuy020, zoals nader omschreven in Artikel 17, wordt de leveringstermijn automatisch verlengd met de duur van de vertraging die door dergelijke omstandigheden is veroorzaakt.</p>

                <h1 className="general-terms-title">Artikel 11. Verplichtingen van de Klant</h1>
                <p className="general-terms-text">11.1 De Klant dient ervoor te zorgen dat alle informatie die iBuy020 noodzakelijk acht voor de uitvoering van de Overeenkomst, of waarvan de Klant redelijkerwijs behoort te begrijpen dat deze noodzakelijk is, tijdig wordt verstrekt.</p>
                <p className="general-terms-text">11.2 Indien de door de Klant verstrekte informatie onvolledig of onjuist is, zijn de gevolgen hiervan voor eigen rekening en risico van de Klant.</p>
                <p className="general-terms-text">11.3 De Klant is verplicht iBuy020 direct te informeren over feiten of omstandigheden die relevant kunnen zijn voor de uitvoering van de Overeenkomst.</p>
                <p className="general-terms-text">11.4 De Klant dient zich te onthouden van activiteiten die de diensten van iBuy020 zouden kunnen belemmeren of iBuy020 zouden kunnen hinderen bij de uitvoering van de Overeenkomst.</p>
                <p className="general-terms-text">11.5 De Klant vrijwaart iBuy020 tegen aanspraken van derden met betrekking tot schade die verband houdt met de uitvoering van de Overeenkomst en die aan de Klant kan worden toegerekend.</p>
                <p className="general-terms-text">11.6 De Klant moet medewerking verlenen aan de verplichtingen van iBuy020 onder de Nederlandse Wet ter voorkoming van witwassen en financieren van terrorisme (Wwft).</p>
                <p className="general-terms-text">11.7 De Klant moet tijdig aanwezig zijn bij geplande afspraken. Indien de Klant te laat is, kan de duur van de afspraak worden ingekort.</p>
                <p className="general-terms-text">11.8 Indien de Klant zonder geldige afmelding een afspraak niet nakomt, en een nieuwe afspraak moet worden ingepland, behoudt iBuy020 zich het recht voor om extra kosten in rekening te brengen. Deze extra kosten zullen vooraf aan de Klant worden meegedeeld.</p>
                <p className="general-terms-text">11.9 Indien de Klant zijn verplichtingen jegens iBuy020 uit hoofde van deze Overeenkomst, deze algemene voorwaarden of wettelijke bepalingen (niet tijdig) niet nakomt, of indien de Klant onrechtmatig jegens iBuy020 handelt, is de Klant aansprakelijk voor alle schade die iBuy020 hierdoor lijdt, inclusief gederfde omzet.</p>

                <h1 className="general-terms-title">Artikel 12. Positie van iBuy020</h1>
                <p className="general-terms-text">12.1 iBuy020 is niet aansprakelijk voor enige schade die voortvloeit uit de situatie waarin een verhuurder of verkoper, mogelijk na onderhandelingen door iBuy020 namens de Klant, geen Huur- of Aankoopovereenkomst met de Klant aangaat.</p>
                <p className="general-terms-text">12.2 iBuy020 is niet aansprakelijk voor de gevolgen van handelen van de verhuurder of verkoper. iBuy020 is niet verantwoordelijk voor de wijze waarop de verhuurder of verkoper zijn verplichtingen nakomt nadat de Huur- of Aankoopovereenkomst is ondertekend.</p>

                <h1 className="general-terms-title">Artikel 13. Courtage voor Huur en Aankoop</h1>
                <p className="general-terms-text">13.1 De courtage die de Klant aan iBuy020 verschuldigd is, is duidelijk vastgelegd in de Overeenkomst.</p>
                <p className="general-terms-text">13.2 Indien de courtage gelijk is aan de huur voor één maand, omvat deze huur tevens, indien van toepassing, de door de Klant verschuldigde vergoeding voor het huren van tastbare objecten van de verhuurder.</p>
                <p className="general-terms-text">13.3 BTW is van toepassing op de courtage.</p>
                <p className="general-terms-text">13.4 De courtage wordt direct gefactureerd nadat de Klant instemt met het concept van de Huur- of Aankoopovereenkomst en wordt uitgenodigd voor digitale ondertekening.</p>
                <p className="general-terms-text">13.5 In geval van aankoop wordt de courtage onmiddellijk na ondertekening van de Aankoopovereenkomst gefactureerd.</p>
                <p className="general-terms-text">13.6 De courtage blijft verschuldigd na beëindiging van de Overeenkomst indien:</p>
                <ul>
                    <li className="general-terms-text">a. iBuy020 voldoende bewijst dat de totstandkoming van de Huur- of Aankoopovereenkomst het resultaat is van de door iBuy020 aan de Klant verleende diensten tijdens de looptijd van de Overeenkomst;</li>
                    <li className="general-terms-text">b. De Klant in strijd heeft gehandeld met Artikel 4.3.</li>
                </ul>
                <p className="general-terms-text">13.7 Indien de opdracht tot bemiddeling door twee of meer personen aan iBuy020 is verstrekt, is iedere persoon hoofdelijk aansprakelijk voor de betaling van de courtage aan iBuy020 op grond van de Overeenkomst en/of deze algemene voorwaarden.</p>
                <p className="general-terms-text">13.8 Indien de verhuurder of verkoper een ontbindende voorwaarde in de Huur- of Aankoopovereenkomst inroept, dient de Klant iBuy020 hiervan onmiddellijk op de hoogte te stellen, en is de courtage niet verschuldigd aan iBuy020.</p>

                <h1 className="general-terms-title">Artikel 14. Betaling</h1>
                <p className="general-terms-text">14.1 De Klant dient de door iBuy020 uitgegeven factuur binnen zeven (7) dagen na de factuurdatum en vóór de sleuteloverdracht te betalen.</p>
                <p className="general-terms-text">14.2 Indien de betalingstermijn wordt overschreden en de Klant een zakelijke entiteit is, is deze een vertragingsrente van 1,5% per maand verschuldigd, of de toepasselijke wettelijke rente voor handelstransacties, welke hoger is, vanaf de datum waarop het verschuldigde bedrag opeisbaar wordt tot het moment van betaling.</p>
                <p className="general-terms-text">14.3 Indien de betalingstermijn wordt overschreden en de Klant een particulier is, is deze de wettelijke rente voor consumententransacties verschuldigd conform artikel 6:119 van het Burgerlijk Wetboek vanaf de datum waarop het verschuldigde bedrag opeisbaar wordt tot het tijdstip van betaling.</p>
                <p className="general-terms-text">14.4 Alle incassokosten die voortvloeien uit het moment van verzuim van de Klant, zowel gerechtelijk als buitengerechtelijk, zijn eveneens voor rekening van de Klant.</p>
                <p className="general-terms-text">14.5 Buitengerechtelijke incassokosten bedragen 15% van de hoofdsom met een minimum van €100 indien de Klant een zakelijke entiteit is.</p>
                <p className="general-terms-text">14.6 Indien de Klant een particulier is, worden de buitengerechtelijke kosten vastgesteld conform de Wet Incassokosten (WIK).</p>
                <p className="general-terms-text">14.7 Vorderingen van iBuy020 op de Klant zijn onmiddellijk opeisbaar in geval van liquidatie, faillissement, beslaglegging of surseance van betaling door de Klant.</p>
                <p className="general-terms-text">14.8 Zodra de Klant in verzuim verkeert, heeft iBuy020 het recht om alle werkzaamheden voor de Klant met onmiddellijke ingang op te schorten. iBuy020 zal de Klant schriftelijk op de hoogte stellen van de opschorting van haar werkzaamheden en is niet aansprakelijk voor enige schade die de Klant hierdoor lijdt.</p>
                <p className="general-terms-text">14.9 Alle betalingen dekken eerst de verschuldigde kosten, gevolgd door de rente en de oudste hoofdsom.</p>
                <p className="general-terms-text">14.10 iBuy020 behoudt het recht om de in het kader van de Overeenkomst verkregen of vervaardigde zaken, gegevens, documenten en dergelijke onder zich te houden, ondanks een bestaande verplichting tot teruggave daarvan, totdat de Klant alle aan iBuy020 verschuldigde bedragen heeft voldaan.</p>

                <h1 className="general-terms-title">Artikel 15. Klachten</h1>
                <p className="general-terms-text">15.1 De Klant dient klachten over de werkzaamheden zo spoedig mogelijk na ontdekking schriftelijk aan iBuy020 voor te leggen. De ingebrekestelling dient een zo gedetailleerd mogelijke omschrijving van de tekortkoming te bevatten, zodat iBuy020 in staat is adequaat te reageren.</p>
                <p className="general-terms-text">15.2 Indien iBuy020 de klacht gegrond acht, zal iBuy020 de werkzaamheden, indien mogelijk, alsnog verrichten zoals overeengekomen.</p>
                <p className="general-terms-text">15.3 De aansprakelijkheid van iBuy020 is altijd beperkt op de wijze zoals vastgelegd in Artikel 16 van deze algemene voorwaarden.</p>
                <p className="general-terms-text">15.4 De Klant dient iBuy020 de gelegenheid te bieden om binnen een redelijke termijn tekortkomingen die aan haar toerekenbaar zijn te herstellen of om de daaruit voortvloeiende schade te beperken of weg te nemen.</p>
                <p className="general-terms-text">15.5 Klachten schorten de betalingsverplichting van de Klant niet op.</p>

                <h1 className="general-terms-title">Artikel 16. Aansprakelijkheid en Verjaring</h1>
                <p className="general-terms-text">16.1 iBuy020 is niet verplicht tot vergoeding van enige schade die direct of indirect voortvloeit uit:</p>
                <ul>
                    <li className="general-terms-text">a. Een omstandigheid die haar niet kan worden toegerekend, zoals nader omschreven in Artikel 17;</li>
                    <li className="general-terms-text">b. Enige handeling of nalaten van de Klant, zijn ondergeschikten of andere personen die werkzaamheden voor of namens de Klant verrichten.</li>
                </ul>
                <p className="general-terms-text">16.2 De Klant is altijd verantwoordelijk voor de juistheid en volledigheid van alle door hem verstrekte informatie. iBuy020 is niet aansprakelijk voor schade die (deels) is veroorzaakt doordat de door de Klant verstrekte gegevens en documenten onjuist of onvolledig zijn of door het opvolgen van de instructies van de Klant. De Klant vrijwaart iBuy020 voor alle aanspraken dienaangaande.</p>
                <p className="general-terms-text">16.3 iBuy020 staat niet in voor de juistheid van door de (potentiële) verhuurder of (potentiële) verkoper verstrekte gegevens. iBuy020 is niet aansprakelijk indien blijkt dat een (potentiële) verhuurder of (potentiële) verkoper onjuiste of onvolledige informatie heeft verstrekt aan iBuy020 en/of de Klant.</p>
                <p className="general-terms-text">16.4 iBuy020 is niet aansprakelijk voor schade die is veroorzaakt door handelingen van de verhuurder, verkoper of enige derde.</p>
                <p className="general-terms-text">16.5 iBuy020 is niet aansprakelijk indien de resultaten voortvloeiend uit de door iBuy020 verrichte werkzaamheden niet aan de verwachtingen van de Klant voldoen.</p>
                <p className="general-terms-text">16.6 Het betreden van een woning voor een bezichtiging geschiedt geheel op eigen risico van de Klant. iBuy020 is niet aansprakelijk voor enige schade die de Klant lijdt tijdens een bezichtiging.</p>
                <p className="general-terms-text">16.7 Indien de werkzaamheden van iBuy020 niet resulteren in een Huur- of Aankoopovereenkomst, kan iBuy020 hiervoor nooit aansprakelijk worden gesteld.</p>
                <p className="general-terms-text">16.8 iBuy020 is niet aansprakelijk voor de gevolgen van een hack.</p>
                <p className="general-terms-text">16.9 iBuy020 is nooit aansprakelijk voor indirecte schade, waaronder begrepen maar niet beperkt tot gevolgschade, winstderving, omzetderving, gemiste besparingen, vertraging, imagoschade, opgelegde boetes, loonkosten, kosten van (tijdelijke) huisvesting, overige huisvestingskosten en schade door bedrijfsonderbreking.</p>
                <p className="general-terms-text">16.10 Indien iBuy020 aansprakelijk wordt bevonden voor enige schade of indien een hierboven vastgelegde beperking van aansprakelijkheid niet door de wet is toegestaan, is de aansprakelijkheid van iBuy020 beperkt tot het bedrag dat door de verzekeraar van iBuy020 wordt uitgekeerd. Indien de verzekeraar niet uitkeert of de schade niet onder de dekking valt, is de aansprakelijkheid van iBuy020 beperkt tot het bedrag dat de Klant heeft betaald voor de werkzaamheden waarop de aansprakelijkheid betrekking heeft, mits dit geen strijd oplevert met een dwingendrechtelijke bepaling.</p>
                <p className="general-terms-text">16.11 iBuy020 is uitsluitend aansprakelijk indien de Klant iBuy020 onmiddellijk en deugdelijk schriftelijk in gebreke stelt, een redelijke termijn gunt om de tekortkoming te verhelpen, en iBuy020 ook na die termijn haar verplichtingen niet nakomt.</p>
                <p className="general-terms-text">16.12 De bepalingen van dit artikel, evenals alle andere beperkingen en uitsluitingen van aansprakelijkheid die in deze algemene voorwaarden zijn opgenomen, gelden tevens ten behoeve van alle door iBuy020 bij de uitvoering van de Overeenkomst betrokken personen/entiteiten.</p>

                <h1 className="general-terms-title">Artikel 17. Overmacht</h1>
                <p className="general-terms-text">17.1. Onder overmacht wordt verstaan, maar niet beperkt tot, extreme weersomstandigheden, diefstal, overstromingen, aardbevingen, terroristische daden, belemmeringen door derden, overheidsbelemmeringen, virusinfectie of hacking door derden, stakingen, verkeersobstructies, onrust, oorlog of oorlogsgevaar, internet- en stroomuitval, storingen in het e-mailverkeer, brand, overheidsmaatregelen, epidemieën, pandemieën, boycot en ziekte of persoonlijke (familie)omstandigheden van de natuurlijke persoon die de Overeenkomst uitvoert of moet uitvoeren namens iBuy020.</p>
                <p className="general-terms-text">17.2. Overmacht omvat ook een niet-toerekenbare tekortkoming van een derde partij die door iBuy020 is ingeschakeld voor de uitvoering van de Overeenkomst.</p>
                <p className="general-terms-text">17.3. iBuy020 heeft ook het recht om zich op overmacht te beroepen indien de omstandigheden die de (verdere) uitvoering van de Overeenkomst beïnvloeden zich voordoen nadat iBuy020 haar verplichtingen had moeten nakomen.</p>
                <p className="general-terms-text">17.4. In geval van overmacht is iBuy020 niet verplicht om enige schadevergoeding te betalen voor directe of indirecte schade en is zij ontslagen van de verplichting om de Overeenkomst uit te voeren. Het hangt af van de omstandigheden van het geval of iBuy020 geheel of gedeeltelijk van haar verplichting is ontslagen, of dat de uitvoering alleen wordt opgeschort. Indien de Overeenkomst (in gewijzigde vorm) kan worden uitgevoerd, zijn zowel iBuy020 als de Klant verplicht om van deze mogelijkheid gebruik te maken.</p>

                <h1 className="general-terms-title">Artikel 18. Opschorting en ontbinding van de Overeenkomst</h1>
                <p className="general-terms-text">18.1. iBuy020 heeft het recht om de nakoming van haar verplichtingen op te schorten of de Overeenkomst zonder tussenkomst van de rechter door middel van een Schriftelijke kennisgeving te ontbinden, indien:</p>
                <ul>
                    <li className="general-terms-text">a. De Klant zijn verplichtingen uit de Overeenkomst niet (volledig) nakomt en de Klant nalaat gehoor te geven aan een ingebrekestelling die hem een redelijke termijn geeft om zijn verplichtingen na te komen. Een ingebrekestelling is niet vereist indien nakoming blijvend onmogelijk is;</li>
                    <li className="general-terms-text">b. iBuy020 na het sluiten van de Overeenkomst omstandigheden verneemt die haar gegronde redenen geven om te vrezen dat de Klant zijn verplichtingen niet zal nakomen.</li>
                </ul>
                <p className="general-terms-text">18.2. iBuy020 heeft het recht om de Overeenkomst tussentijds te ontbinden zonder tussenkomst van de rechter door middel van een Schriftelijke kennisgeving indien iBuy020 dwingende redenen heeft om dit te doen, zoals maar zeker niet beperkt tot de volgende omstandigheden:</p>
                <ul>
                    <li className="general-terms-text">a. De samenwerking tussen de Klant en iBuy020 is ernstig verstoord geraakt;</li>
                    <li className="general-terms-text">b. De Klant stelt eisen aan het gewenste pand in combinatie met een maximale huur- of koopprijs die niet realistisch zijn en/of niet in lijn zijn met marktstandaarden;</li>
                    <li className="general-terms-text">c. iBuy020 heeft tevens een bemiddelingsopdracht geaccepteerd van de verhuurder of verkoper van een woning die de Klant wil huren of kopen.</li>
                </ul>
                <p className="general-terms-text">18.3. iBuy020 heeft tevens het recht om de Overeenkomst te ontbinden indien zich omstandigheden voordoen die de uitvoering van de Overeenkomst onmogelijk maken, indien uitvoering in alle redelijkheid en billijkheid niet langer kan worden verwacht, of indien zich andere omstandigheden voordoen waardoor de ongewijzigde voortzetting van de Overeenkomst niet langer redelijkerwijs kan worden verwacht.</p>
                <p className="general-terms-text">18.4. iBuy020 heeft het recht om de Overeenkomst te ontbinden indien de Klant surseance van betaling aanvraagt of wordt verleend, indien de Klant failliet wordt verklaard of faillissement aanvraagt, indien de Klant niet in staat is zijn schulden te betalen, onder curatele wordt gesteld, of indien een bewindvoerder wordt benoemd.</p>

                <h1 className="general-terms-title">Artikel 19. Vertrouwelijkheid</h1>
                <p className="general-terms-text">19.1. Beide partijen verplichten zich tot geheimhouding van alle vertrouwelijke informatie die in het kader van de Overeenkomst van elkaar of uit een andere bron is verkregen. Informatie is vertrouwelijk als de andere partij dit heeft aangegeven of als dit uit de aard van de informatie voortvloeit. De partij die vertrouwelijke informatie ontvangt, zal deze informatie alleen gebruiken voor het doel waarvoor deze is verstrekt.</p>
                <p className="general-terms-text">19.2. Indien iBuy020 op grond van een wettelijke bepaling of een rechterlijke uitspraak verplicht is vertrouwelijke informatie aan door de wet of de bevoegde rechter aangewezen derden te verstrekken, en iBuy020 zich niet kan beroepen op een wettelijk dan wel door de bevoegde rechter erkend of toegestaan recht van verschoning, is iBuy020 niet gehouden tot schadevergoeding of schadeloosstelling en heeft de Klant niet het recht om de Overeenkomst te ontbinden.</p>

                <h1 className="general-terms-title">Artikel 20. Privacy</h1>
                <p className="general-terms-text">20.1. iBuy020 verwerkt persoonsgegevens in overeenstemming met de Algemene Verordening Gegevensbescherming (AVG). De Klant kan de privacyverklaring van iBuy020 raadplegen voor meer informatie over de verwerking van persoonsgegevens, beschikbaar op <a rel='noopener' href="https://ibuy020.com/privacy-policy/" target="_blank">https://ibuy020.com/privacy-policy/</a></p>

                <h1 className="general-terms-title">Artikel 21. Vervaltermijn</h1>
                <p className="general-terms-text">21.1. Voor zover in deze algemene voorwaarden niet anders is bepaald, vervallen alle vorderingen van de Klant op welke grond dan ook jegens iBuy020 in ieder geval 1 jaar na het moment waarop de Klant bekend werd of redelijkerwijs bekend had kunnen zijn met het bestaan van deze rechten.</p>

                <h1 className="general-terms-title">Artikel 22. Bevoegde rechter en toepasselijk recht</h1>
                <p className="general-terms-text">22.1. Alle geschillen die voortvloeien uit Overeenkomsten tussen de Klant en iBuy020 zullen worden voorgelegd aan de bevoegde rechter in het arrondissement waar iBuy020 is gevestigd (Amsterdam). Indien de Klant een Natuurlijk Persoon is, kan hij ervoor kiezen om het geschil binnen 1 maand nadat iBuy020 zich Schriftelijk op dit artikel heeft beroepen, voor te leggen aan de wettelijk bevoegde rechter.</p>
                <p className="general-terms-text">22.2. Op alle Overeenkomsten tussen iBuy020 en de Klant is het Nederlands recht van toepassing.</p>
            </div>
        </PageLayout>
    );
};

export default GeneralTermsView;