import React from 'react';
import './CompletedPopUp.css';

const CompletedPopUp = () => {
    return (
        <div className='completed-popup-overlay'>
            <div className="completed-popup-wrapper-div">
                <div className="completed-popup-container">

                    <svg className="checkmarkdone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="34" cy="34" r="40" fill="none" />
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke-width="3" stroke-linecap="round" />
                    </svg>

                    <h1 className="completed-title-h1">Zoekopdracht verstuurd!</h1>
                    <p className="completed-txt-p">Je zoekopdracht is nu ontvangen door ons en wordt verwerkt. We zullen zo snel mogelijk contact met jou opnemen. In je e-mail ontvang je de ondergetekende opdrachtbevestiging.</p>
                </div>
            </div>
        </div>
    );
};

export default CompletedPopUp;