import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import './Home.css';
import video from '../../assets/video/indexvideo-small.mp4';
import imageadam from '../../assets/img/img-adam-01.jpg';
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import usePageTracking from '../../utils/usePageTracking';

const Home: React.FC = () => {
    usePageTracking();

        return (
            <PageLayout>
                <Helmet>
                    <title>iBuy 020 | Huis kopen doe je zo | Home</title>
                    <meta name="description" content="Vind jouw droomhuis met iBuy020. Wij begeleiden je persoonlijk door het aankoopproces en zorgen voor de beste deal. Altijd bereikbaar, zelfs buiten kantooruren. Kies iBuy020 voor een zorgeloze woningkoop." />
                </Helmet>
                <div id="home-area-div">
                    <div id="home-inner-video-area-div">
                        <motion.video
                            src={video}
                            id="home-inner-video"
                            controls={false}
                            autoPlay={true}
                            muted={true}
                            playsInline={true}
                            loop
                            preload="auto"
                            initial={{ scale: 0.5, x: 0, opacity: 0 }}
                            whileInView={{ scale: 1, x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            style={{ pointerEvents: "none" }} // Add this line to make it non-clickable
                        ></motion.video>
                    </div>
                    <div id="home-inner-txt-area-div">
                        <div id="home-inner-txt-block-div">
                            <motion.h1 id="home-inner-txt-block-h1" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} >iBuy 020 - Jouw aankoopmakelaar in Amsterdam</motion.h1>
                            <motion.p id="home-inner-txt-block-p" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 0.5 }} viewport={{ once: true }}>Vind jouw droomhuis met iBuy020. Wij begeleiden je persoonlijk door het aankoopproces en zorgen voor de beste deal. Altijd bereikbaar, zelfs buiten kantooruren.<br /><br /> Kies iBuy020 voor een zorgeloze woningkoop.</motion.p>
                            <Link to="/zoekopdracht" className="home-inner-action-butt">
                                <motion.button id="home-inner-action-butt" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 1 }} viewport={{ once: true }}>Start een zoekopdracht</motion.button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div id="home-diensten-area-div">
                    <div id="home-diensten-inner-area-div">
                        <div id="home-diensten-grid-wrapper-div">
                            <motion.div id="home-dienst-item-div" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 0 }} viewport={{ once: true }}>
                                <span id="home-dienst-icon-span" className="material-symbols-rounded">cottage</span>
                                <h3 id="home-dienst-tile-h3">Wij zoeken een geschikte woning</h3>
                            </motion.div>

                            <motion.div id="home-dienst-item-div" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 0.5 }} viewport={{ once: true }}>
                                <span id="home-dienst-icon-span" className="material-symbols-rounded">sell</span>
                                <h3 id="home-dienst-tile-h3">Wij onderhandelen over de prijs</h3>
                            </motion.div>

                            <motion.div id="home-dienst-item-div" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 1 }} viewport={{ once: true }}>
                                <span id="home-dienst-icon-span" className="material-symbols-rounded">search</span>
                                <h3 id="home-dienst-tile-h3">Wij onderzoeken de woning van A tot Z</h3>
                            </motion.div>
                        </div>

                    </div>
                </div>
                <div id="home-waarom-area-div">
                    <div id="home-waarom-img-area-div">
                        <motion.img id="home-waarom-img" alt='foto van amsterdam' src={imageadam} initial={{ scale: 0.5, opacity: 0 }} whileInView={{ scale: 1, opacity: 1 }} viewport={{ once: true }} />
                    </div>
                    <div id="home-waarom-inner-area">
                        <div id="home-waarom-txt-area-div">
                            <motion.h1 id="home-waarom-txt-h1" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 0.5 }} viewport={{ once: true }}>Waarom je een aankoopmakelaar nodig hebt bij het kopen van een huis</motion.h1>
                            <motion.p id="home-waarom-txt-p" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 1 }} viewport={{ once: true }}>Als je op zoek bent naar een huis, kan het proces overweldigend zijn en veel stress met zich meebrengen. Een aankoopmakelaar kan je helpen bij het maken van weloverwogen beslissingen en het verminderen van de stress.<br /><br />Een aankoopmakelaar heeft grondige kennis van de lokale vastgoedmarkt en de bijbehorende wet- en regelgeving. Zo kan hij/zij je helpen bij het bepalen van de juiste prijs voor een woning en het voeren van de onderhandelingen met de verkoper.<br /><br />Daarnaast heeft een aankoopmakelaar toegang tot een breed netwerk van professionals in de vastgoedsector, zoals hypotheekadviseurs, notarissen en bouwkundige inspecteurs. Dit kan je helpen om mogelijke obstakels tijdig te identificeren en op te lossen.<br /><br />Een aankoopmakelaar kan je ook begeleiden bij de juridische aspecten van het kopen van een woning, zoals het opstellen van de koopovereenkomst en het regelen van de overdracht.</motion.p>
                            <motion.button id="home-inner-action-butt" initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ delay: 1 }} viewport={{ once: true }}>Start een zoekopdracht</motion.button>
                        </div>
                    </div>
                </div>
            </PageLayout>
        );
}

export default Home;