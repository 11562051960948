import { SearchAssignment } from "../../models/SearchAssignment";
import api from "../api";

export const addSearchAssignment = async (searchAssignment: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const url = '/search_assignments/'
        const response = await api.post(url, searchAssignment, config);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getSearchAssignmentByColumn = async (column: string, value: any,): Promise<SearchAssignment> => {
    try {
        const url = `/search_assignments/${column}/${value}`;
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};