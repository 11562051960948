import React from "react";
import { Input } from "@nextui-org/react";
import SignaturePad from 'react-signature-pad-wrapper';

function StepOnderteknen({ formData, setFormData, validationErrors, signaturePadRef }) {
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,

        });
    };

    return (
        <div id='ondertekenen' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Onderteken je zoekopdracht</h1>
                    <p className="input-form-text-p">Ondergetekende heeft de Algemene Voorwaarden en de Privacy Overeenkomst van iRent 020 ontvangen en hiervan kennisgenomen. De Algemene Voorwaarden vormen een integraal onderdeel van deze opdrachtbevestiging. Ondergetekende verklaart zich door ondertekening van deze opdrachtbevestiging medeakoord met de inhoud hiervan. Deze offerte is geldig voor een onbepaald termijn en is geheel kostenloos. Voor de verdere bepalingen word verwezen naar de Algemene Voorwaarden.</p>
                </div>
                <Input
                    name='city_signature'
                    type="text"
                    label="Stad ondertekening"
                    variant="bordered"
                    onChange={handleChange}
                    size="lg"
                    className="input-form-gegevens"
                />
                {validationErrors.city_signature && <div className="error-message">{validationErrors.city_signature}</div>}
                <div className="signature-pad">
                    <SignaturePad ref={signaturePadRef} redrawOnResize/>
                    <button className="clear-button" onClick={() => signaturePadRef.current.clear()}><span className="material-symbols-rounded clear-btn-icon-span" translate='no'>refresh</span></button>
                </div>
                {validationErrors.signature && <div className="error-message">{validationErrors.signature}</div>}
            </div>
        </div>
    );
}

export default StepOnderteknen;