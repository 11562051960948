import React, { useState } from "react";
import { CheckboxGroup } from "@nextui-org/react";
import { CustomCheckbox } from "../../../../components/zoekopdracht-components/CustomCheckbox/CustomCheckbox.js";

function StepSelecteerOpties({ formData, setFormData, nextStep, options }) {
    const handleChange = (newValues) => {
        setFormData({
            ...formData,
            options: newValues,
        });
    };

    return (
        <div id='selecteeropties' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Selecteer de opties die jij belangrijk vindt</h1>
                    <p className="input-form-text-p">Vink hieronder de opties aan die jij belangrijk vindt voor jouw nieuwe koopwoning.</p>
                </div>
                <div className="zoekopdracht-input-form-checkbox-area">
                    <CheckboxGroup
                        name="options"
                        className="gap-1"
                        orientation="horizontal"
                        value={formData.options}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <CustomCheckbox key={option['id']} value={option['name']}>{option['name']}</CustomCheckbox>
                        ))}
                    </CheckboxGroup>
                </div>
            </div>
        </div>
    );
}

export default StepSelecteerOpties;