import React from "react";
import { Select, SelectItem } from "@nextui-org/react";

function StepTypeWoning({ formData, setFormData, validationErrors, woningTypes }) {
    const handleChange = (event) => {
        setFormData({
            ...formData,
            property_type: event.target.value,
        });
    };

    
    return (
        <div id='typewoning' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Kies het type woning</h1>
                    <p className="input-form-text-p">Kies hieronder het type voor je nieuwe woning.</p>
                </div>
                <Select
                    name="property_type"
                    variant='bordered'
                    label="Selecteer woning type"
                    value={formData.property_type}
                    onChange={handleChange}
                    selectedKeys={formData.property_type ? [formData.property_type] : []}
                >
                    {woningTypes.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                            {type.label}
                        </SelectItem>
                    ))}
                </Select>
                {validationErrors.property_type && <div className="error-message">{validationErrors.property_type}</div>}
            </div>
        </div>
    );
}

export default StepTypeWoning;