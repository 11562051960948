import React from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import './PrivacyPolicyView.css';
import { Helmet } from "react-helmet-async";
import usePageTracking from '../../utils/usePageTracking';

const PrivacyPolicyView: React.FC = () => {
    usePageTracking();
    return (
        <PageLayout>
            <Helmet>
                <title>iBuy 020 | Privacy beleid</title>
                <meta name="description" content="Privacy beleid van iBuy 020" />
            </Helmet>
            <div className="privacy-policy-inner-content">
                <h1 className="privacy-policy-title">Privacy Overeenkomst</h1>
                <p className="privacy-policy-text">
                    iBuy020, gevestigd aan Eemstraat 68 H, 1079 TK Amsterdam, Noord-Holland, Nederland, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                </p>

                <h1 className="privacy-policy-title">Contactgegevens:</h1>
                <p className="privacy-policy-text">
                    <a href="https://ibuy020.nl" target="_blank" rel='noopener'>https://ibuy020.nl</a><br />
                    Eemstraat 68 H, 1079 TK Amsterdam, Noord-Holland, Nederland
                </p>
                <p className="privacy-policy-text">
                    Max Brummer is de Functionaris Gegevensbescherming van iBuy020. Hij is te bereiken via <a href="mailto:max@ibuy020.nl">max@ibuy020.nl</a>
                </p>

                <h1 className="privacy-policy-title">Persoonsgegevens die wij verwerken</h1>
                <p className="privacy-policy-text">
                    iBuy020 verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
                </p>
                <p className="privacy-policy-text">
                    Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                </p>
                <ul>
                    <li className="privacy-policy-text">Voor- en achternaam</li>
                    <li className="privacy-policy-text">Geboortedatum</li>
                    <li className="privacy-policy-text">Geboorteplaats</li>
                    <li className="privacy-policy-text">Adresgegevens</li>
                    <li className="privacy-policy-text">Telefoonnummer</li>
                    <li className="privacy-policy-text">E-mailadres</li>
                    <li className="privacy-policy-text">Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
                    <li className="privacy-policy-text">Gegevens over uw activiteiten op onze website</li>
                </ul>

                <h1 className="privacy-policy-title">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h1>
                <p className="privacy-policy-text">
                    iBuy020 verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van u:
                </p>
                <ul>
                    <li className="privacy-policy-text">burgerservicenummer (BSN)</li>
                </ul>

                <h1 className="privacy-policy-title">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h1>
                <p className="privacy-policy-text">
                    iBuy020 verwerkt uw persoonsgegevens voor de volgende doelen:
                </p>
                <ul>
                    <li className="privacy-policy-text">Het afhandelen van uw betaling</li>
                    <li className="privacy-policy-text">U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                    <li className="privacy-policy-text">U de mogelijkheid te bieden een account aan te maken</li>
                    <li className="privacy-policy-text">Om goederen en diensten bij u af te leveren</li>
                    <li className="privacy-policy-text">iBuy020 analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.</li>
                </ul>

                <h1 className="privacy-policy-title">Hoe lang we persoonsgegevens bewaren</h1>
                <p className="privacy-policy-text">
                    iBuy020 bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:
                </p>
                <ul>
                    <li className="privacy-policy-text">Voor- en achternaam &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">Adres &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">E-mailadres &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">Telefoonnummer &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">BSN-nummer &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">Geboortedatum &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                    <li className="privacy-policy-text">Geboorteplaats &gt; Half jaar mits al je zoekopdracht(en) zijn afgerond &gt; Dan kunnen wij je zo goed en snel mogelijk helpen met je zoekopdracht</li>
                </ul>

                <h1 className="privacy-policy-title">Delen van persoonsgegevens met derden</h1>
                <p className="privacy-policy-text">
                    iBuy020 verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting.
                </p>

                <h1 className="privacy-policy-title">Cookies, of vergelijkbare technieken, die wij gebruiken</h1>
                <p className="privacy-policy-text">
                    iBuy020 gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
                </p>

                <h1 className="privacy-policy-title">Gegevens inzien, aanpassen of verwijderen</h1>
                <p className="privacy-policy-text">
                    U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van uw account. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door ons bedrijf en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.
                </p>
                <p className="privacy-policy-text">
                    Wilt u gebruik maken van uw recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heeft u andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek via het portaal.
                </p>
                <p className="privacy-policy-text">
                    Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. iBuy020 zal zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren.
                </p>
                <p className="privacy-policy-text">
                    iBuy020 wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:
                </p>
                <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank" rel='noopener'>klik hier</a>

                <h1 className="privacy-policy-title">Hoe wij persoonsgegevens beveiligen</h1>
                <p className="privacy-policy-text">
                    iBuy020 neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a href="mailto:info@ibuy020.nl">info@ibuy020.nl</a>
                </p>
            </div>
        </PageLayout>
    );
};

export default PrivacyPolicyView;