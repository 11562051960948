import React from "react";
import { Input } from "@nextui-org/react";

function StepBudget({ formData, setFormData, validationErrors }) {
  const formatValue = (value) => {
    const numericValue = Number(value.replace(/\./g, "").replace(/,/g, "."));
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(numericValue).replace(/\s*€$/, '');
  };

  const handleBudgetBlur = () => {
    const euro = formatValue(formData.budget);
    setFormData({
      ...formData,
      budget: euro
    });
  };

const handleBudgetChange = (event) => {
  setFormData({
    ...formData,
    budget: event.target.value,
  });
};

  return (
    <div id='budget' className="zoekopdracht-input-form-area">
      <div className="zoekopdracht-input-form-wrapper">
        <div className="zoekopdracht-input-form-top-area">
          <h1 className="input-form-title-h1">Kies je budget</h1>
          <p className="input-form-text-p">Voer het budget in dat je wilt besteden aan je nieuwe woning.</p>
        </div>
        <Input
          className="zoekopdracht-input-form-input"
          name="budget"
          type="text"
          placeholder="0,00"
          labelPlacement="outside"
          variant="bordered"
          size="lg"
          value={formData.budget}
          onChange={handleBudgetChange}
          onBlur={handleBudgetBlur}
          startContent={
            <div className="pointer-events-none flex items-center">
              <span className="text-default-400 text-small">€</span>
            </div>
          }
        />
        {validationErrors.budget && <div className="error-message">{validationErrors.budget}</div>}

      </div>
    </div>
  );
}

export default StepBudget;