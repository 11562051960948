import './App.css';
import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import HuisKopen from './pages/Huis-Kopen/Huis-Kopen';
import Contact from './pages/Contact/Contact';
import Zoekopdracht from './pages/Zoekopdracht/Zoekopdracht';
import ZoekopdrachtVoltooid from './pages/ZoekopdrachtVoltooid/ZoekopdrachtVoltooid.tsx';
import GeneralTermsView from './pages/GeneralTermsView/GeneralTermsView';
import PrivacyPolicyView from './pages/PrivacyPolicyView/PrivacyPolicyView';
import { NextUIProvider } from "@nextui-org/react";
import { HelmetProvider } from 'react-helmet-async';

// import { Listbox, ListboxItem } from "@nextui-org/react";
// import { ListboxWrapper } from "./components/ListboxWrapper.jsx";

function App() {
  return (
    <HelmetProvider>
      <NextUIProvider>
        <Router>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/" exact element={<Home />} />
            <Route path="/huis-kopen" element={<HuisKopen />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/zoekopdracht" element={<Zoekopdracht />} />
            <Route path="/zoekopdracht-voltooid" element={<ZoekopdrachtVoltooid />} />
            <Route path="/algemene-voorwaarden" element={<GeneralTermsView />} />
            <Route path="/privacy-beleid" element={<PrivacyPolicyView />} />
          </Routes>
        </Router>
      </NextUIProvider>
    </HelmetProvider>
  );
}

export default App;
