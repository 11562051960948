import React from "react";
import { Slider } from "@nextui-org/react";

function StepAantalVierkanteMeters({ formData, setFormData, nextStep }) {
    const handleChange = (newValue) => {
        setFormData({
            ...formData,
            square_meters: newValue,
        });
    };

    return (
        <div id='aantalvierkantemeters' className="zoekopdracht-input-form-area">
            <div className="zoekopdracht-input-form-wrapper">
                <div className="zoekopdracht-input-form-top-area">
                    <h1 className="input-form-title-h1">Geef je voorkeur voor het aantal vierkante meters van jouw koopwoning</h1>
                    <p className="input-form-text-p">Vul hieronder de voorkeur in voor het aantal vierkante meters van de koopwoning.</p>
                </div>
                <Slider
                    name='square_meters'
                    label="Vierkante meters"
                    step={1}
                    maxValue={1000}
                    minValue={20}
                    onChange={handleChange}
                    defaultValue={formData.square_meters}
                />
            </div>
        </div>
    );
}

export default StepAantalVierkanteMeters;