import React from 'react';
import './NavBar.css';
import logo from '../../assets/img/ibuy-logo.jpg';
import { useMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';


const NavBar: React.FC = () => {
    const homeactive = useMatch("/");
    const huiskopenactive = useMatch("/huis-kopen");
    const contactactive = useMatch("/contact");
    return (
        <nav id='main-navbar'>
            <ul id="mainmenu-listing-ul">
                <li id="mainmenu-logo-li">
                    <Link to="/">
                        <img src={logo} alt='ibuy-logo' id="mainmenu-logo-img" />
                    </Link>
                </li>
                <li id="mainmenu-links-li">
                    <ul id="mainmenu-links-listing-ul">
                        <Link to="/">
                            <li className={`mainmenu-link-li  ${homeactive ? 'active' : ''}`}>
                                <p id="mainmenu-link-txt-p">Home</p>
                                <div id="mainmenu-link-eff-div">

                                </div>
                            </li>
                        </Link>

                        <Link to="/huis-kopen">
                            <li className={`mainmenu-link-li  ${huiskopenactive ? 'active' : ''}`}>
                                <p id="mainmenu-link-txt-p">Huis kopen</p>
                                <div id="mainmenu-link-eff-div">

                                </div>
                            </li>
                        </Link>

                        <Link to="/contact">
                            <li className={`mainmenu-link-li  ${contactactive ? 'active' : ''}`}>
                                <p id="mainmenu-link-txt-p">Contact</p>
                                <div id="mainmenu-link-eff-div">

                                </div>
                            </li>
                        </Link>
                    </ul>
                </li>
                <li id="mainmenu-quick-butts-li">
                    <Link to="/zoekopdracht">
                        <button id="mainmenu-quick-butt">
                            Start een zoekopdracht
                        </button>
                    </Link>
                </li>
                <li className="mainmenu-hamburger-li">
                    <HamburgerMenu />
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;